export const noDataAvailable = 'Відсутні дані'
export const noResultsFound = "Нічого не знайдено"

export  const card = {
    mainInfo: "Основна інформація",
    errorMessage: "Сталася непередбачена помилка при обробці інформації. Будь ласка, зверніться до відділу підтримки для отримання допомоги."
};
export const fetchTimeout = 10000;
export const ALLOWED_PLACEMENTS = ['topRight', 'topLeft', 'bottomRight', 'bottomLeft', 'top', 'bottom'];
export const isObject = (value) => !Array.isArray(value) && typeof value === "object"
export const STATUS = {
    IDLE: 'Idle',
    PENDING: 'Pending',
    SUCCESS: 'Success',
    ERROR: 'Error',
}

export const dateTimeOptions = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric'
};

export const formatDate = (date, separator = '') => {
    return new Date(date).toString() !== 'Invalid Date'
        ? separator + new Intl.DateTimeFormat('ua', dateTimeOptions).format(new Date(date))
        : ''
}

export const booleanArray = [
    {
        label: 'Так',
        value: true,
    }, {
        label: 'Ні',
        value: false,
    }
]

export const moduleStatus = [
    {
        label: 'Stable',
        value: 'stable',
    }, {
        label: 'Alpha',
        value: 'alpha',
    },{
        label: 'Beta',
        value: 'beta',
    },

]

export const operationsItem = [
    {
        label: "Додавання",
        value: "INSERT",
    },
    {
        label: "Оновлення",
        value: "UPDATE",
    },
    {
        label: "Видалення",
        value: "DELETE",
    }
]

export const operationsSecureItem = [
    {
        label: "Авторизовано",
        value: "success",
    },
    {
        label: "Не авторизовано",
        value: "error",
    },
    {
        label: "Невідомий користувач",
        value: "unknown",
    }
]

export const accessGroupItem = [
    {
        label: "Перегляд",
        value: "VIEW",
    },
    {
        label: "Додавання",
        value: "INSERT",
    },
    {
        label: "Редагування",
        value: "EDIT",
    },
    {
        label: "Видалення",
        value: "DELETE",
    }
]

export const iconMap = {
    success: (
        <path
            fillRule="evenodd"
            d="M9.128.233c-2.37 1.383-5.37 2.33-7.635 2.646-.821.115-1.495.79-1.493 1.62l.001.497c-.03 6.043.477 11.332 9.462 14.903a1.45 1.45 0 001.062 0c8.993-3.571 9.503-8.86 9.473-14.903v-.501c-.001-.828-.674-1.51-1.492-1.638-2.148-.337-5.281-1.274-7.65-2.628a1.733 1.733 0 00-1.728.004zm4.577 8.478a1 1 0 00-1.414-1.415L8.998 10.59 7.705 9.297A1 1 0 106.29 10.71l2 2.001a1 1 0 001.414 0l4-4.001z"
        />
    ),
    info: (
        <path
            fillRule="evenodd"
            d="M0 10C0 4.478 4.478 0 10 0c5.523 0 10 4.478 10 10 0 5.523-4.477 10-10 10-5.522 0-10-4.477-10-10zm11.125 2.002H8.989v-.141c.01-1.966.492-2.254 1.374-2.782.093-.056.19-.114.293-.178.73-.459 1.292-1.038 1.292-1.883 0-.948-.743-1.564-1.666-1.564-.851 0-1.657.398-1.712 1.533H6.304C6.364 4.693 8.18 3.5 10.294 3.5c2.306 0 3.894 1.447 3.894 3.488 0 1.382-.695 2.288-1.805 2.952l-.238.144c-.79.475-1.009.607-1.02 1.777V12zm.17 3.012a1.344 1.344 0 01-1.327 1.328 1.32 1.32 0 01-1.328-1.328 1.318 1.318 0 011.328-1.316c.712 0 1.322.592 1.328 1.316z"
        />
    ),
    warning: (
        <path
            fillRule="evenodd"
            d="M1.16 16.829l7.498-15c.553-1.106 2.13-1.106 2.683 0l7.498 15A1.5 1.5 0 0117.498 19H2.502a1.5 1.5 0 01-1.342-2.171zM10 7a1 1 0 011 1v3a1 1 0 01-2 0V8a1 1 0 011-1zm1 8a1 1 0 11-2 0 1 1 0 012 0z"
        />
    ),
    back: (
        <path
            d="M17 9H5.414l3.293-3.293a.999.999 0 10-1.414-1.414l-5 5a.999.999 0 000 1.414l5 5a.997.997 0 001.414 0 .999.999 0 000-1.414L5.414 11H17a1 1 0 100-2z"></path>
    ),
    delete: (
        <path
            d="M8 3.994C8 2.893 8.895 2 10 2s2 .893 2 1.994h4c.552 0 1 .446 1 .997 0 .55-.448.997-1 .997H4c-.552 0-1-.447-1-.997s.448-.997 1-.997h4zM5 14.508V8h2v6.508a.5.5 0 00.5.498H9V8h2v7.006h1.5a.5.5 0 00.5-.498V8h2v6.508A2.496 2.496 0 0112.5 17h-5C6.12 17 5 15.884 5 14.508z"></path>
    ),
    edit: (
        <>
            <path
                d="M14.7782 2.06066C15.364 1.47487 16.3137 1.47487 16.8995 2.06066C17.4853 2.64645 17.4853 3.59619 16.8995 4.18198L14.7782 2.06066Z"/>
            <path
                d="M14.0711 2.76777L16.1924 4.88909L10.5356 10.5459L8.06069 10.8995L8.41424 8.42463L14.0711 2.76777Z"/>
            <path
                d="M0 8C0 6.89543 0.89543 6 2 6H6V8H2V16H18V8H17V6H18C19.1046 6 20 6.89543 20 8V16C20 17.1046 19.1046 18 18 18H2C0.89543 18 0 17.1046 0 16V8Z"/>
            <path d="M4 13C4 12.4477 4.44772 12 5 12H12.9993L12.9999 14H5C4.44772 14 4 13.5523 4 13Z"/>
            <path d="M12.9993 12C13.5516 12 14 12.4477 14 13C14 13.5523 13.5522 14 12.9999 14L12.9993 12Z"/>
        </>
    ),
    profile: (
        <path
            d="M14.363 5.22a4.22 4.22 0 11-8.439 0 4.22 4.22 0 018.439 0zM2.67 14.469c1.385-1.09 4.141-2.853 7.474-2.853 3.332 0 6.089 1.764 7.474 2.853.618.486.81 1.308.567 2.056l-.333 1.02A2.11 2.11 0 0115.846 19H4.441a2.11 2.11 0 01-2.005-1.455l-.333-1.02c-.245-.748-.052-1.57.567-2.056z"/>
    ),
    exit: (
        <>
            <path
                d="M2.43934 17.5607C2.15804 17.2794 2 16.8978 2 16.5V15C2 14.7348 2.10536 14.4804 2.29289 14.2929C2.48043 14.1054 2.73478 14 3 14C3.26522 14 3.51957 14.1054 3.70711 14.2929C3.89464 14.4804 4 14.7348 4 15V16H16V4H4V5C4 5.26522 3.89464 5.51957 3.70711 5.70711C3.51957 5.89464 3.26522 6 3 6C2.73478 6 2.48043 5.89464 2.29289 5.70711C2.10536 5.51957 2 5.26522 2 5V3.5C2 3.10218 2.15804 2.72064 2.43934 2.43934C2.72064 2.15804 3.10218 2 3.5 2H16.5C16.8978 2 17.2794 2.15804 17.5607 2.43934C17.842 2.72064 18 3.10218 18 3.5V16.5C18 16.8978 17.842 17.2794 17.5607 17.5607C17.2794 17.842 16.8978 18 16.5 18H3.5C3.10218 18 2.72064 17.842 2.43934 17.5607Z"/>
            <path
                d="m12.707 9.292-3-3c-0.1875-0.18756-0.44189-0.29291-0.70703-0.29291-0.04761 0-0.09521 0.00342-0.14233 0.0102-0.08228 0.01184-0.16309 0.03393-0.24024 0.06592-0.12134 0.05029-0.23169 0.1239-0.32446 0.21679-0.09277 0.09284-0.16651 0.20307-0.2168 0.32434-0.05005 0.12134-0.07593 0.25135-0.07593 0.38263 0 0.2652 0.10523 0.51947 0.29273 0.70697l1.293 1.293h-7.5859c-0.26514 0-0.51953 0.10535-0.70703 0.29291-0.1875 0.1875-0.29297 0.44189-0.29297 0.70709 0 0.26524 0.10547 0.51964 0.29297 0.70714s0.44189 0.2929 0.70703 0.2929h7.5859l-1.293 1.293c-0.02735 0.0272-0.05298 0.056-0.07691 0.0861-0.05737 0.0723-0.10449 0.1525-0.14013 0.2382-0.0503 0.1213-0.07617 0.2513-0.07617 0.3827 0 0.1313 0.02587 0.2613 0.07617 0.3827 0.05029 0.1213 0.12402 0.2315 0.21704 0.3242 0.0415 0.0418 0.08667 0.0797 0.13476 0.1134 0.05909 0.0414 0.12256 0.0765 0.18946 0.1043 0.12134 0.0504 0.25146 0.0764 0.38281 0.0764 0.06421 0 0.12817-0.0062 0.19092-0.0185 0.06567-0.0127 0.12988-0.0322 0.19189-0.0579 0.12134-0.0505 0.23145-0.1245 0.32422-0.2177l3-3c0.093-0.0927 0.1668-0.2029 0.2171-0.3242 0.0298-0.0719 0.051-0.1469 0.0632-0.2234 0.0085-0.0526 0.0129-0.1059 0.0129-0.15934 0-0.13135-0.0258-0.26135-0.0761-0.38269s-0.1241-0.2315-0.2171-0.32428z"/>
        </>
    ),
    add: (
        <path
            d="M0 10c0 5.514 4.486 10 10 10s10-4.486 10-10S15.514 0 10 0 0 4.486 0 10zm5 0a1 1 0 011-1h3V6a1 1 0 112 0v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 01-1-1z"/>
    ),
    view: (
        <path
            d="M17.928 9.628C17.837 9.399 15.611 4 10 4S2.162 9.399 2.07 9.628a1.017 1.017 0 000 .744C2.163 10.601 4.389 16 10 16c5.611 0 7.837-5.399 7.928-5.628a1.017 1.017 0 000-.744zM10 14a4 4 0 110-8 4 4 0 010 8zm0-6a2 2 0 10.002 4.001A2 2 0 009.999 8z"/>
    ),
    filter: (
        <path
            d="M0 5a1 1 0 011-1h18a1 1 0 110 2H1a1 1 0 01-1-1zm3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm5 4a1 1 0 100 2h4a1 1 0 100-2H8z"/>
    ),
    save: (
        <path fillRule="evenodd"
              d="M17.5 19a1.5 1.5 0 001.5-1.5v-13A1.5 1.5 0 0017.5 3h-6.879c-.397 0-.779-.158-1.06-.44L8.44 1.439A1.503 1.503 0 007.379 1H2.5A1.5 1.5 0 001 2.5v15A1.5 1.5 0 002.5 19h15zM9 11.586l-1.293-1.293a1 1 0 00-1.414 1.414l3 3a.997.997 0 001.414 0l3-3a1 1 0 00-1.414-1.414L11 11.586V8a1 1 0 00-2 0v3.586z"></path>
    ),
    password: (
        <path fillRule="evenodd"
              d="M4 6v2H2.5A1.5 1.5 0 001 9.5v9A1.5 1.5 0 002.5 20h15a1.5 1.5 0 001.5-1.5v-9A1.5 1.5 0 0017.5 8H16V6A6 6 0 004 6zm6-4a4 4 0 00-4 4v2h8V6a4 4 0 00-4-4zm1 14v-4H9v4h2z"></path>
    ),
    search: (
        <path
            d="M2 8c0-3.309 2.691-6 6-6s6 2.691 6 6-2.691 6-6 6-6-2.691-6-6zm17.707 10.293l-5.395-5.396A7.946 7.946 0 0016 8c0-4.411-3.589-8-8-8S0 3.589 0 8s3.589 8 8 8a7.954 7.954 0 004.897-1.688l5.396 5.395A.998.998 0 0020 19a1 1 0 00-.293-.707z"></path>
    ),
    prev: (
        <path
            d="M12 16a.997.997 0 01-.707-.293l-5-5a.999.999 0 010-1.414l5-5a.999.999 0 111.414 1.414L8.414 10l4.293 4.293A.999.999 0 0112 16z"/>

    ),
    next: (
        <path
            d="M8 16a.999.999 0 01-.707-1.707L11.586 10 7.293 5.707a.999.999 0 111.414-1.414l5 5a.999.999 0 010 1.414l-5 5A.997.997 0 018 16z"/>
    ),
    close: (
        <path
            d="M6.707 5.293a1 1 0 0 0-1.414 1.414L8.586 10l-3.293 3.293a1 1 0 1 0 1.414 1.414L10 11.414l3.293 3.293a1 1 0 0 0 1.414-1.414L11.414 10l3.293-3.293a1 1 0 0 0-1.414-1.414L10 8.586 6.707 5.293Z"/>
    ),
    arrowUp: (
        <path
            d="M10 14a.997.997 0 01-.707-.293l-5-5a.999.999 0 111.414-1.414L10 11.586l4.293-4.293a.999.999 0 111.414 1.414l-5 5A.997.997 0 0110 14z"/>
    ),
    tooltip: (
        <path fillRule="evenodd"
              d="M0 10C0 4.478 4.478 0 10 0c5.523 0 10 4.478 10 10 0 5.523-4.477 10-10 10-5.522 0-10-4.477-10-10zm11.125 2.002H8.989v-.141c.01-1.966.492-2.254 1.374-2.782.093-.056.19-.114.293-.178.73-.459 1.292-1.038 1.292-1.883 0-.948-.743-1.564-1.666-1.564-.851 0-1.657.398-1.712 1.533H6.304C6.364 4.693 8.18 3.5 10.294 3.5c2.306 0 3.894 1.447 3.894 3.488 0 1.382-.695 2.288-1.805 2.952l-.238.144c-.79.475-1.009.607-1.02 1.777V12zm.17 3.012a1.344 1.344 0 01-1.327 1.328 1.32 1.32 0 01-1.328-1.328 1.318 1.318 0 011.328-1.316c.712 0 1.322.592 1.328 1.316z"></path>
    ),
    dash: (
        <path
            d="M14.1667 9H5.83333C5.3725 9 5 9.448 5 10C5 10.552 5.3725 11 5.83333 11H14.1667C14.6275 11 15 10.552 15 10C15 9.448 14.6275 9 14.1667 9"></path>
    ),
    dots: (
        <path
            d="M6 10a2 2 0 11-4.001-.001A2 2 0 016 10zm6 0a2 2 0 11-4.001-.001A2 2 0 0112 10zm6 0a2 2 0 11-4.001-.001A2 2 0 0118 10z"></path>
    ),
    arrowDown: (
        <path
            d="M13.098 8H6.902c-.751 0-1.172.754-.708 1.268L9.292 12.7c.36.399 1.055.399 1.416 0l3.098-3.433C14.27 8.754 13.849 8 13.098 8Z"></path>
    ),
    default: null,
}

export const generateIcon = (iconPath, className, color, width, height) => {
    return (
        <svg width={width} height={height} viewBox="0 0 20 20" fill={color} className={className}>
            {iconPath}
        </svg>
    );
};