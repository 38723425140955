import React from 'react';
import './dashboard.css'
const Dashboard = () => {

    return (
        <div className="page-home">
            <div className="container">
                <div className="page-home__inner">
                    <h1 className="title title--main">
                        Програмний комплекс ChervonohradCityBot
                    </h1>
                    <p className="paragraph paragraph--lg">
                        Вітаємо в програмі! Скористайтесь меню для переходу у бажаний розділ.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Dashboard;