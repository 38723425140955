import React, { Suspense, lazy } from "react";
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import PageError from "./pages/ErrorPage/PageError"
import Loader from "./components/Loader/Loader"
import Home from './pages/Home/Home'
import Dashboard from './pages/Dashboard/Dashboard'

const UserList = lazy(() => import('./pages/Users/UserList'));
const UserView = lazy(()=>import('./pages/Users/UserView'))
const UserForm = lazy(()=>import('./components/Forms/UserForm'))
const Group = lazy(() => import('./pages/AccessGroup/AccessGroup'));
const Access = lazy(()=>import('./pages/AccessGroup/Access'))
const GroupView = lazy(()=>import('./pages/AccessGroup/AccessGroupView'))
const AccessGroupForm = lazy(()=>import('./components/Forms/AccessGroupForm'))
const Log = lazy(() => import('./pages/LogDisplay/Log'));
const Secure = lazy(()=>import('./pages/LogDisplay/Secure'))
const BlackList = lazy(()=>import('./pages/LogDisplay/BlackList'))
const LogView = lazy(()=>import('./pages/LogDisplay/LogView'))
const Profile = lazy(() => import('./pages/Profile/Profile'));
const BlogList = lazy(() => import('./pages/Blog/BlogList'));
const BlogForm = lazy(() => import('./components/Forms/BlogForm'));
const GuideList  = lazy(()=>import('./pages/Guide/GuideList'))
const GuideView = lazy(()=>import('./pages/Guide/GuideView'))
const GuideForm = lazy(()=>import('./components/Forms/GuideForm'))
const GuideListData  = lazy(()=>import('./pages/Guide/GuideListData'))
const GuideDataView = lazy(()=>import('./pages/Guide/GuideDataView'))
const GuideDataForm = lazy(()=>import('./components/Forms/GuideDataForm'))
const ModuleList  = lazy(()=>import('./pages/Module/ModuleList'))
const ModuleListView = lazy(()=>import('./pages/Module/ModuleListView'))
const ModuleForm = lazy(()=>import('./components/Forms/ModuleForm'))
const RegistryList  = lazy(()=>import('./pages/Registry/RegistryList'))
const RegistryListView = lazy(()=>import('./pages/Registry/RegistryListView'))
const RegistryForm = lazy(()=>import('./components/Forms/RegistryForm'))
function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<Home />}>
          <Route index element={<Dashboard />} />
          <Route exact path="user" element={<Suspense fallback={<Loader />}><UserList /></Suspense>} />
          <Route path="user/:userId" element={<Suspense fallback={<Loader/>}><UserView /></Suspense>} />
          <Route exact path="user/add" element={<Suspense fallback={<Loader/>}><UserForm /></Suspense>} />
          <Route path="user/:userId/edit" element={<Suspense fallback={<Loader/>}><UserForm /></Suspense>} />
          <Route exact path="group" element={<Suspense fallback={<Loader />}><Group /></Suspense>} />
          <Route path="group/:roleId" element={<Suspense fallback={<Loader/>}><GroupView /></Suspense>} />
          <Route exact path="group/add" element={<Suspense fallback={<Loader />}><AccessGroupForm /></Suspense>}/>
          <Route exact path="group/:roleId/access" element={<Suspense fallback={<Loader />}><Access /></Suspense>}/>
          <Route path="group/:roleId/edit" element={<Suspense fallback={<Loader />}><AccessGroupForm /></Suspense>}/>
          <Route exact path="logs" element={<Suspense fallback={<Loader />}><Log /></Suspense>} />
          <Route path="logs/:id" element={<Suspense fallback={<Loader />}><LogView /></Suspense>} />
          <Route exact path="/secure" element={<Suspense fallback={<Loader />}><Secure /></Suspense>} />
          <Route exact path="/blacklist" element={<Suspense fallback={<Loader />}><BlackList /></Suspense>} />
          <Route path="profile" element={<Suspense fallback={<Loader />}><Profile /></Suspense>} />
          <Route exact path="/news" element={<Suspense fallback={<Loader />}><BlogList /></Suspense>} />
          <Route exact path="news/add" element={<Suspense fallback={<Loader/>}><BlogForm /></Suspense>} />
          <Route path="news/:newsId/edit" element={<Suspense fallback={<Loader/>}><BlogForm /></Suspense>} />
          <Route exact path="listGuides" element={<Suspense fallback={<Loader />}><GuideList /></Suspense>} />
          <Route path="listGuides/:guideId" element={<Suspense fallback={<Loader />}><GuideView /></Suspense>} />
          <Route exact path="listGuides/add" element={<Suspense fallback={<Loader/>}><GuideForm /></Suspense>} />
          <Route path="listGuides/:guideId/edit" element={<Suspense fallback={<Loader/>}><GuideForm /></Suspense>} />
          <Route exact path="dataGuides" element={<Suspense fallback={<Loader />}><GuideListData /></Suspense>} />
          <Route path="dataGuides/:guideId" element={<Suspense fallback={<Loader />}><GuideDataView /></Suspense>} />
          <Route exact path="dataGuides/add" element={<Suspense fallback={<Loader/>}><GuideDataForm /></Suspense>} />
          <Route path="dataGuides/:guideId/edit" element={<Suspense fallback={<Loader/>}><GuideDataForm /></Suspense>} />
          <Route exact path="modules" element={<Suspense fallback={<Loader />}><ModuleList /></Suspense>} />
          <Route path="modules/:moduleId" element={<Suspense fallback={<Loader />}><ModuleListView /></Suspense>} />
          <Route exact path="modules/add" element={<Suspense fallback={<Loader/>}><ModuleForm /></Suspense>} />
          <Route path="modules/:moduleId/edit" element={<Suspense fallback={<Loader/>}><ModuleForm /></Suspense>} />
          <Route exact path="registry" element={<Suspense fallback={<Loader />}><RegistryList /></Suspense>} />
          <Route path="registry/:registryId" element={<Suspense fallback={<Loader />}><RegistryListView /></Suspense>} />
          <Route exact path="registry/add" element={<Suspense fallback={<Loader/>}><RegistryForm /></Suspense>} />
          <Route path="registry/:registryId/edit" element={<Suspense fallback={<Loader/>}><RegistryForm /></Suspense>} />
          <Route path="*" element={<PageError title="Схоже, цієї сторінки не знайдено." statusError="404"/>}/>
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
