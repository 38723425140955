import React, {createContext} from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './index.css'
import Store from './store/store';
import NotificationProvider from "./provider/NotificationProvider";

const store = new Store();

export const Context = createContext({
    store,
})

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <NotificationProvider>
        <Context.Provider value={{store}}>
            <App/>
        </Context.Provider>
    </NotificationProvider>
);

