import React, {useCallback, useContext, useState} from 'react';
import logo from "../../images/logo.webp";
import './Header.css'
import Menu from "../Menu/Menu";
import classNames from "classnames";
import {Context} from "../../index";
import LazyImage from "../common/LazyImage/LazyImage";

const Header = ({activeKey, selectedKey}) => {
    console.log('Header render')
    const [isOpen, setIsOpen] = useState(false);
    const {store} = useContext(Context)

    const toggleMenu = useCallback(() => {
        console.log('TOGGLE MENU')
        setIsOpen(!isOpen);
    }, [isOpen])

    return (
        <header className="header">
            <div className="header__top">
                <div className="container">
                    <div className="header__top-inner">
                        <div className="header__logo">

                            <LazyImage src={logo} alt={"Логотип"} className={"header__logo-img"}/>
                            <p className="header__logo-text">
                                Червоноградьска <br/> міська рада
                            </p>
                        </div>
                        <button
                            className={classNames('header__top-burger-btn', {"header__top-burger-btn--active": isOpen})}
                            aria-label={`${isOpen ? "Приховати навігаційне меню" : "Показати навігаційне меню"}`}
                            aria-expanded={isOpen} aria-controls="header__nav" onClick={toggleMenu}>
                            <span></span><span></span><span></span>
                        </button>
                        <div
                            className={classNames("header__top-closing-field", {"header__top-closing-field--active": isOpen})}
                            title={`${isOpen ? "Приховати навігаційне меню" : "Показати навігаційне меню"}`}
                            onClick={() => {
                                if (isOpen) setIsOpen(false)
                            }}
                        >
                        </div>
                    </div>
                </div>
            </div>

            <nav className={`header__nav ${isOpen ? "header__nav--active" : ""}`} id="header__nav">
                {isOpen && (
                    <Menu
                        menu={store.user?.access_group}
                        activeKey={activeKey}
                        selectedKey={selectedKey}
                        isSearch
                        onClick={toggleMenu}
                    />
                )}
            </nav>
        </header>
    );
};
export default Header;